import React, { memo, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { getCurrentSemanticMap } from "Slices/semanticMaps";
import { MapComponent } from "Components/MapComponent";
import {
  IntersectionsWindowStyled,
  IntersectionsSidebarStyled,
  ToggleSatButtonStyled,
  IntersectionsWrapperStyled,
} from "./styles";
import { IntersectionsList } from "Components/Intersections/components/IntersectionsList";

export const Intersections = memo(() => {
  const [showSatView, setShowSatView] = useState<boolean>(false);
  const currentSemanticMap = useSelector(getCurrentSemanticMap);

  const toggleSatView = () => {
    setShowSatView(!showSatView);
  };
  const mapBounds = useMemo(() => {
    if (!currentSemanticMap) return;
    const { bbox } = currentSemanticMap;
    if (bbox && bbox[0]) {
      return bbox;
    }
    return;
  }, [currentSemanticMap]);

  return (
    <IntersectionsWrapperStyled>
      {currentSemanticMap && (
        <IntersectionsSidebarStyled>
          <IntersectionsList />
        </IntersectionsSidebarStyled>
      )}
      <IntersectionsWindowStyled>
        <MapComponent
          currentSemanticMap={currentSemanticMap}
          mapBounds={mapBounds}
          showSatView={showSatView}
        />
        <ToggleSatButtonStyled onClick={toggleSatView}></ToggleSatButtonStyled>
      </IntersectionsWindowStyled>
    </IntersectionsWrapperStyled>
  );
});
