import React, { memo } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getIntersectionEntries,
  getIsIntersectionsLoaded,
  getIsIntersectionsLoadingError,
  fetchPatchPermission,
} from "Slices/intersections";
import { AsideLoader } from "Components/common/Loader/index";
import { IntersectionItem } from "./IntersectionItem";

import {
  IntersectionListBodyStyled,
  IntersectionItemWrapperStyled,
} from "./styles";

export const IntersectionEntities = memo(() => {
  const intersectionData = useSelector(getIntersectionEntries);
  const isIntersectionsLoaded = useSelector(getIsIntersectionsLoaded);
  const hasIntersectionsLoadingError = useSelector(
    getIsIntersectionsLoadingError
  );

  const dispatch = useDispatch();

  return (
    <IntersectionListBodyStyled data-testid="intersection-list-entities">
      {hasIntersectionsLoadingError ? (
        <IntersectionItemWrapperStyled standAlone={true}>
          Something went wrong. Please try it again.
        </IntersectionItemWrapperStyled>
      ) : !isIntersectionsLoaded ? (
        <AsideLoader />
      ) : intersectionData.length === 0 ? (
        <IntersectionItemWrapperStyled standAlone={true}>
          No entry permissions found for current zone
        </IntersectionItemWrapperStyled>
      ) : (
        intersectionData.map((intersection) => {
          const { id, intersection_id, vehicle_id } = intersection;
          return (
            <IntersectionItem
              data={intersection}
              key={id}
              onClickHandler={() => {
                const payload = {
                  intersection_id,
                  entry_permission_id: id,
                  vehicle_id,
                  successMessage:
                    "The entry permission has been successfully revoked",
                };
                dispatch(fetchPatchPermission(payload));
              }}
            />
          );
        })
      )}
    </IntersectionListBodyStyled>
  );
});
