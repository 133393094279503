import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../../index";
import { DataStatus } from "../../types";

export const getIntersectionEntries = (state: RootState) =>
  state.intersections.data;

const getIntersectionsStatus = (state: RootState) => state.intersections.status;

export const getSematicIntersectionMapping = (state: RootState) =>
  state.intersections.semanticIntersectionMapping;

const getSematicIntersectionStatus = (state: RootState) =>
  state.intersections.sematicIntersectionStatus;

export const getIsIntersectionsLoaded = createSelector(
  getIntersectionsStatus,
  (status) => status === DataStatus.succeeded
);
export const getIsIntersectionsLoadingError = createSelector(
  getSematicIntersectionStatus,
  getIntersectionsStatus,
  (semanticIntersectionStatus, intersectionStatus) =>
    semanticIntersectionStatus === DataStatus.failed ||
    intersectionStatus === DataStatus.failed
);

export const getIsSematicIntersectionMapLoaded = createSelector(
  getSematicIntersectionStatus,
  (status) => status === DataStatus.succeeded
);
