import { memo, useContext, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useFMSWebsocket } from "Utils/webSocketUtils";
import { getCurrentSemanticMapId } from "Slices/appState";
import { VEHICLE_STATE_WS, WS_SPINNER_LOGS } from "Utils/constants";
import { setVehiclesStateData, updateVehicle } from "Slices/vehiclesState";
import { WebsocketLoaderContext } from "Pages/AuthorizedApp/WebsocketLoaderController";
import { FMSLogger } from "FMSLogger";

const wsSpinnerLogs = FMSLogger.byPrefix(`${WS_SPINNER_LOGS}:Intersections`);

const UPDATING_FIELDS = [
  "t_long",
  "t_lat",
  "t_drive_mode",
  "t_web_status",
  "t_virtual_bumper_status",
  "t_vehicle_status",
  "last_contact",
];

export const IntersectionsController = memo(() => {
  const semanticMapId = useSelector(getCurrentSemanticMapId);

  const setIsWSConnecting = useContext(WebsocketLoaderContext);

  useLayoutEffect(
    () => () => {
      wsSpinnerLogs.debug("unmount component spinner off");
      setIsWSConnecting?.(false);
    },
    []
  );

  useEffect(() => {
    if (semanticMapId) {
      wsSpinnerLogs.debug("new semantic map spinner on");
      setIsWSConnecting?.(true);
    } else {
      wsSpinnerLogs.debug("no semantic map spinner off");
      setIsWSConnecting?.(false);
    }
  }, [semanticMapId]);

  const dispatch = useDispatch();

  const handleVehicleStateCreateMessage = (data: VehicleState[]) => {
    wsSpinnerLogs.debug("create message spinner off");
    setIsWSConnecting?.(false);
    dispatch(setVehiclesStateData(data));
  };

  const handleVehicleStateUpdateMessage = (data: VehicleState) => {
    dispatch(updateVehicle(data));
  };

  useFMSWebsocket({
    url: "/vehicles/state/",
    subscriptionData: semanticMapId,
    loggerFlag: VEHICLE_STATE_WS,
    getDataForSubscribe: (subscriptionId) => ({
      semantic_map_ids: [subscriptionId],
      fields: UPDATING_FIELDS,
    }),
    getDataForUnsubscribe: (subscriptionId) => ({
      semantic_map_ids: [subscriptionId],
    }),
    handleInitialMessage: handleVehicleStateCreateMessage,
    handleMessage: handleVehicleStateUpdateMessage,
  });

  return null;
});
