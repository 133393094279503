import React, { memo } from "react";

import { SemanticMapPicker } from "Components/common/SemanticMapPicker";
import { IntersectionEntities } from "./IntersectionEntities";

import { IntersectionsListStyled, SemanticMapPickerBodyStyled } from "./styles";

export const IntersectionsList = memo(() => {
  return (
    <IntersectionsListStyled data-testid="intersections-list-styled">
      <SemanticMapPickerBodyStyled data-testid="semantic-map-picker-body-styled">
        <SemanticMapPicker />
      </SemanticMapPickerBodyStyled>
      <IntersectionEntities />
    </IntersectionsListStyled>
  );
});
