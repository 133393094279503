import { createSlice } from "@reduxjs/toolkit";

import {
  fetchGetIntersections,
  fetchGetIntersectionIdsFromSemanticMap,
} from "./actions";
import { DataStatus } from "../../types";

type IntersectionsState = {
  semanticIntersectionMapping: Record<string, number[]> | null;
  data: Array<IntersectionEntity>;
  sematicIntersectionStatus: DataStatus;
  sematicIntersectionError: string | null;
  status: DataStatus;
  error: string | null;
};

const initialState: IntersectionsState = {
  semanticIntersectionMapping: null,
  data: [],
  sematicIntersectionStatus: DataStatus.idle,
  sematicIntersectionError: null,
  status: DataStatus.idle,
  error: null,
};

export const Slice = createSlice({
  name: "intersections",
  initialState,
  reducers: {
    resetIntersections: () => initialState,
    revokeEntryPermission: (state, { payload }) => {
      state.data = state.data.filter(
        (intersection) => intersection.id !== payload
      );
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetIntersectionIdsFromSemanticMap.pending, (state) => {
        state.sematicIntersectionStatus = DataStatus.loading;
        state.sematicIntersectionError = null;
      })
      .addCase(
        fetchGetIntersectionIdsFromSemanticMap.fulfilled,
        (state, action) => {
          state.sematicIntersectionStatus = DataStatus.succeeded;
          state.semanticIntersectionMapping = action.payload;
        }
      )
      .addCase(
        fetchGetIntersectionIdsFromSemanticMap.rejected,
        (state, action) => {
          state.sematicIntersectionStatus = DataStatus.failed;
          state.sematicIntersectionError = action.error.message ?? "";
        }
      )
      .addCase(fetchGetIntersections.pending, (state) => {
        state.status = DataStatus.loading;
        state.error = null;
      })
      .addCase(fetchGetIntersections.fulfilled, (state, action) => {
        state.status = DataStatus.succeeded;
        state.data = action.payload;
      })
      .addCase(fetchGetIntersections.rejected, (state, action) => {
        state.status = DataStatus.failed;
        state.error = action.error.message ?? "";
      });
  },
});

export default Slice;
