import React, { memo, useState } from "react";

import {
  IntersectionItemWrapperStyled,
  IntersectionItemColumnsStyled,
  IntersectionItemDetailToggleStyled,
  IntersectionExpandDetailStyled,
  IntersectionSubTitleStyled,
  IntersectionCancelActionStyled,
} from "./styles";

import { ReactComponent as IntersectionIcon } from "../assets/intersectionIcon.svg";
import { ReactComponent as CancelIcon } from "../assets/cancelIcon.svg";
import { ReactComponent as ArrowDownIcon } from "../../common/assets/selectIcon.svg";

interface IntersectionItemProps {
  data: IntersectionEntity;
  onClickHandler: () => void;
}

export const IntersectionItem = memo(
  ({ data, onClickHandler }: IntersectionItemProps) => {
    const [showDetail, setShowDetail] = useState(false);

    const { id, events, vehicle_id } = data;
    const name = `intersection ${id}`;
    return (
      <IntersectionItemWrapperStyled key={id}>
        <IntersectionItemColumnsStyled>
          <IntersectionItemColumnsStyled>
            <IntersectionIcon />
            <div>{name}</div>
          </IntersectionItemColumnsStyled>
          <IntersectionItemDetailToggleStyled active={showDetail}>
            Detail <ArrowDownIcon onClick={() => setShowDetail(!showDetail)} />
          </IntersectionItemDetailToggleStyled>
        </IntersectionItemColumnsStyled>
        {showDetail && (
          <IntersectionExpandDetailStyled>
            {events &&
              events.length > 0 &&
              events.map((event, index) => {
                const { timestamp, event_data, permissionId } = event;
                const timeString = timestamp.split(".")[0].split("T").join(" ");
                return (
                  <IntersectionItemColumnsStyled key={`event-${index}`}>
                    <div>{timeString}</div>
                    <div>
                      {event_data}
                      <div>entry-permission: {permissionId}</div>
                    </div>
                  </IntersectionItemColumnsStyled>
                );
              })}
            <IntersectionSubTitleStyled>
              Active Permissions
            </IntersectionSubTitleStyled>
            <IntersectionItemColumnsStyled key={`permission-${id}`}>
              <div>entry-permission: {id}</div>
              <div>Vehicle: {vehicle_id}</div>
              <IntersectionCancelActionStyled
                onClick={onClickHandler}
                title="revoke the permission"
              >
                <CancelIcon />
              </IntersectionCancelActionStyled>
            </IntersectionItemColumnsStyled>
          </IntersectionExpandDetailStyled>
        )}
      </IntersectionItemWrapperStyled>
    );
  }
);
