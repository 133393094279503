import {
  fetchGetIntersections,
  fetchPatchPermission,
  fetchGetIntersectionIdsFromSemanticMap,
} from "./actions";
import {
  getIntersectionEntries,
  getIsIntersectionsLoaded,
  getIsIntersectionsLoadingError,
  getSematicIntersectionMapping,
  getIsSematicIntersectionMapLoaded,
} from "./selectors";
import IntersectionsSlice from "./reducers";

// actions
export const { resetIntersections, revokeEntryPermission } =
  IntersectionsSlice.actions;

// async actions
export {
  fetchGetIntersections,
  fetchPatchPermission,
  fetchGetIntersectionIdsFromSemanticMap,
};

// selectors
export {
  getIntersectionEntries,
  getIsIntersectionsLoaded,
  getIsIntersectionsLoadingError,
  getSematicIntersectionMapping,
  getIsSematicIntersectionMapLoaded,
};

export default IntersectionsSlice.reducer;
