import React, { memo } from "react";

import { IntersectionsController } from "./Intersections.controller";
import { IntersectionsLoader } from "./Intersections.loader";
import { Intersections } from "Components/Intersections/Intersections";

export const IntersectionsPage = memo(() => {
  return (
    <IntersectionsLoader>
      <IntersectionsController />
      <Intersections />
    </IntersectionsLoader>
  );
});
